import React from "react";
import { calculateLengthPercent } from "../../utils/utils";
import styles from "./List.module.css";

import { getLanguageData } from "../../utils/languageData.js";

import { Flag } from "../Flags/Flags";

export const List = ({ children }) => {
	return <ul className={styles.translations}>{children}</ul>;
};

export const ListItem = ({
	text,
	lengthTo,
	lengthFrom,
	languageTo,
	languageFrom
}) => {
	const languageData = getLanguageData(languageTo);

	return (
		<li className={styles.translation}>
			<div
				className={styles.translationLanguage}
				alt={languageData.languageName}
			>
				<Flag countryCode={languageTo} />
			</div>
			<div className={styles.translationText}>{text}</div>
			<ul className={styles.translationLength}>
				<li>{lengthTo} characters</li>
				<li>
					{calculateLengthPercent(
						lengthTo,
						lengthFrom,
						languageTo,
						languageFrom
					)}
				</li>
			</ul>
		</li>
	);
};
