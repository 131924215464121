import React from "react";
import styles from "./Header.module.css";
import logo from "./logo.svg";

import { Waves } from "../Waves/Waves";
import { Input } from "../Input/Input";

export const Header = ({ value, onChange, onSubmit }) => {
	return (
		<header>
			<Waves />
			<div className={styles.container}>
				<img className={styles.logo} src={logo} alt="Polyglot logo" />
				<h2 className={styles.tagline}>
					Translate and compare multiple languages at once
				</h2>
				<Input value={value} onChange={onChange} onSubmit={onSubmit} />
			</div>
		</header>
	);
};
