import React from "react";
import styles from "./Waves.module.css";

export const Waves = ({ children }) => {
	return (
		<div className={styles.waves}>
			<div className={styles.wave} />
			<div className={styles.wave} />
		</div>
	);
};
