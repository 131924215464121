export function getLanguageData(languageCode) {
	return Object.keys(languageData)
		.filter(key => languageCode.includes(key))
		.reduce((obj, key) => {
			return (obj[key] = languageData[key]);
		}, {});
}

const languageData = {
	en: {
		languageCode: "en",
		languageName: "English"
	},
	de: {
		languageCode: "de",
		languageName: "German"
	},
	ru: {
		languageCode: "ru",
		languageName: "Russian"
	},
	es: {
		languageCode: "es",
		languageName: "Spanish"
	},
	fr: {
		languageCode: "fr",
		languageName: "French"
	},
	el: {
		languageCode: "el",
		languageName: "Greek"
	},
	pl: {
		languageCode: "pl",
		languageName: "Polish"
	},
	bg: {
		languageCode: "bg",
		languageName: "Bulgarian"
	},
	it: {
		languageCode: "it",
		languageName: "Italian"
	}
};
