import axios from "axios";

const languageFrom = "en";
const languagesTo = ["en", "ru", "es", "de", "fr", "el", "pl", "bg", "it"];
const translateUrl =
	"https://api.cognitive.microsofttranslator.com";
const apiKey = "f57c10a375674930b6eef56038ee462f";

async function translate(textToTranslate) {
	return await axios({
		baseURL: translateUrl,
		url: '/translate',
		method: 'post',
		headers: {
			'Ocp-Apim-Subscription-Key': apiKey,
			'Content-type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
		params: {
			'api-version': '3.0',
			'from': languageFrom,
			'to': languagesTo
		},
		data: [{
			'text': textToTranslate
		}],
		responseType: 'json'
	}).then(function (response) {
		// Map = Add `languageFrom`, `lengthTo` and `lengthFrom` data to object
		// Sort = order translations longest to shortest
		return response.data[0].translations.map(function (translation) {
			return {
				text: translation.text,
				languageTo: translation.to,
				languageFrom: languageFrom,
				lengthTo: translation.text.length,
				lengthFrom: textToTranslate.length
			}
		}).sort(function(a, b) {
		return b.lengthTo - a.lengthTo;
	});
	})
}

export default translate;
