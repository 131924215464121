import React from "react";
import styles from "./Loader.module.css";

import loaderBackground from "./loader-background.svg";
import loader1 from "./loader-1.svg";
import loader2 from "./loader-2.svg";
import loader3 from "./loader-3.svg";

export const Loader = ({ value, onChange, onSubmit }) => {
	return (
		<div className={styles.loader}>
			<div className={styles.loaderImage}>
				<img src={loaderBackground} alt="" />
				<img src={loader1} alt="" />
				<img src={loader2} alt="" />
				<img src={loader3} alt="" />
			</div>
			<p className={styles.loaderText}>
				Just a second, we’re traveling a long way to get these…
			</p>
		</div>
	);
};
