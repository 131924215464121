import React, { Component } from "react";
import translate from "./translate";
import { objectNotEmpty } from "./utils/utils";

import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Dialog } from "./components/Dialog/Dialog";
import { Loader } from "./components/Loader/Loader";
import { List, ListItem } from "./components/List/List";

class App extends Component {
	constructor() {
		super();

		this.state = {
			textToTranslate: "",
			languageFrom: "",
			languagesTo: [],
			translations: {},
			showingDialog: false,
			loadingTranslations: false
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.toggleDialog = this.toggleDialog.bind(this);
		this.fetchTranslations = this.fetchTranslations.bind(this);
	}

	componentDidMount() {
		if (window.navigator.standalone) {
			document.querySelector("body").classList.add("homepage");
		}

		const urlParams = new URLSearchParams(window.location.search);
		const text = urlParams.get("text");

		// https://stackoverflow.com/questions/38558200/react-setstate-not-updating-immediately
		this.setState(
			{
				textToTranslate: text
			},
			() => {
				if (this.state.textToTranslate) {
					this.fetchTranslations();
				}
			}
		);
	}

	onChange(e) {
		this.setState({
			textToTranslate: e.target.value
		});
	}

	onSubmit(e) {
		e.preventDefault();

		e.target.querySelector("input").blur(); // Hide keyboard on mobile

		if (!this.state.textToTranslate) return;

		this.fetchTranslations();
	}

	toggleDialog() {
		this.setState({
			showingDialog: !this.state.showingDialog
		});
	}

	async fetchTranslations() {
		this.setState({ loadingTranslations: true, translations: {} });

		const translations = await translate(this.state.textToTranslate);

		this.setState({
			translations: translations,
			languagesTo: translations.map(translation => translation.languageTo)
		});

		this.setState({ loadingTranslations: false });

		const { textToTranslate, languageFrom, languagesTo } = this.state;
		const pageUrl = `?text=${textToTranslate}&languageFrom=${languageFrom}&languagesTo=${languagesTo.join(
			","
		)}`;

		document.title = `Translating "${textToTranslate}" into ${languagesTo.join(
			", "
		)} — Polyglot`;
		window.history.pushState("", "", pageUrl);
	}

	render() {
		const { translations, loadingTranslations } = this.state;

		return (
			<div className="App">
				<Header
					value={this.state.textToTranslate}
					onChange={this.onChange}
					onSubmit={this.onSubmit}
				/>

				{loadingTranslations ? <Loader /> : null}

				<List>
					{objectNotEmpty(translations)
						? translations.map((translation, index) => (
							<ListItem {...translation} key={index} />
						))
						: null}
				</List>
				<Footer toggleDialogButton={this.toggleDialog} />

				<Dialog
					closeButton={this.toggleDialog}
					showingDialog={this.state.showingDialog}
				/>
			</div>
		);
	}
}

export default App;
