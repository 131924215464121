import React from "react";
import styles from "./Footer.module.css";

export const Footer = ({ children, toggleDialogButton }) => {
	return (
		<footer className={styles.footer}>
			<button onClick={toggleDialogButton}>About the project</button> · Made by{" "}
			<a href="https://twitter.com/mattanddesign">Matt And Design</a>
		</footer>
	);
};
