import React from "react";
import styles from "./Dialog.module.css";

import closeIcon from "./icon-close.svg";

export const Dialog = ({ showingDialog, closeButton }) => {
	if (!showingDialog) {
		return null;
	}

	return (
		<div className={styles.dialogMask}>
			<div className={styles.dialog}>
				<h2 className={styles.heading}>About</h2>
				<p className={styles.paragraph}>
					A common problem when internationalising a product is not allowing
					enough space for the translated text.{" "}
					<a href="https://hellopolyglot.com">Hellopolyglot.com</a> will give
					you an indication of how much room to allow.
				</p>
				<p className={styles.paragraph}>
					Inspired by{" "}
					<a href="https://medium.com/dropbox-design/design-for-internationalization-24c12ea6b38f">
						Design for internationalization
					</a>
					.
				</p>
				<p className={styles.paragraph}>
					Made by{" "}
					<a href="https://twitter.com/mattanddesign">Matt And Design</a>.
				</p>

				<h2 className={styles.heading}>Feedback</h2>

				<p className={styles.paragraph}>
					Feedback can be tweeted to <a href="https://twitter.com/mattanddesign">@mattanddesign</a>
				</p>
			</div>

			<button onClick={closeButton} className={styles.dialogClose}>
				<img src={closeIcon} alt="Close dialog icon" />
			</button>
		</div>
	);
};
