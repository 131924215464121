import React from "react";
import styles from "./Input.module.css";

export const Input = ({ children, onChange, onSubmit, value }) => {
	return (
		<form id="form" className={styles.form} onSubmit={onSubmit}>
			<input
				id="input"
				type="search"
				className={styles.input}
				name="textToTranslate"
				placeholder="Type what you'd like to translate"
				value={value}
				onChange={onChange}
			/>
		</form>
	);
};
