export function objectNotEmpty(obj) {
	// https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
	return Object.keys(obj).length === 0 && obj.constructor === Object
		? false
		: true;
}

export function calculateLengthPercent(
	lengthTo,
	lengthFrom,
	languageTo,
	languageFrom
) {
	let percent = Math.round((lengthTo / lengthFrom) * 100) - 100;

	if (percent > 0) {
		return `${percent}% increase`;
	} else if (percent < 0) {
		return `${Math.abs(percent)}% decrease`;
	} else {
		if (languageTo === languageFrom) {
			return "Original language";
		}

		return "Same length";
	}
}
